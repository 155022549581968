<template>
  <el-dialog :title="employee?.name" v-model="shouldShow" width="70%">
    <el-table
      :data="files"
      v-loading="isLoading"
      @row-click="downloadFile"
      row-style="cursor:pointer;"
    >
      <el-table-column prop="file.clientName" label="nome"> </el-table-column>
      <el-table-column prop="obs" label="descrição"> </el-table-column>
      <el-table-column
        label="validade"
        :formatter="(r) => formatDate(r.expires_at)"
      >
      </el-table-column>
      <el-table-column label="ações" :min-width="100">
        <template #default="f">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                @confirm="deleteFile(f.row)"
                title="
              Remover arquivo?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-row justify="end" type="flex">
        <el-button
          type="text"
          class="button"
          @click="openCreateEmployeeFileModal()"
          >Novo Arquivo</el-button
        >
      </el-row>
    </template>
    <create-employee-file-modal
      :showModal="showCreateEmployeeFileModal"
      :file="file"
      :employee="employee"
      @close-modal="showCreateEmployeeFileModal = false"
      @should-update="fetchFiles"
    ></create-employee-file-modal>
  </el-dialog>
</template>
<script>
import { ElNotification } from "element-plus";
import CreateEmployeeFileModal from "./CreateEmployeeFileModal.vue";
export default {
  name: "EmployeeFilesModal",
  components: { CreateEmployeeFileModal },
  props: ["employee", "showModal"],
  emits: ["close-modal"],
  watch: {
    employee() {
      this.fetchFiles();
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  data() {
    return {
      files: null,
      file: null,
      isLoading: false,
      showCreateEmployeeFileModal: false,
    };
  },
  methods: {
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "desconhecido";
    },
    openCreateEmployeeFileModal(file) {
      this.file = file;
      this.showCreateEmployeeFileModal = true;
    },
    deleteFile(file) {
      if (file) {
        this.isLoading = true;
        fetch(
          `${this.$store.state.apiUrl}employees/${this.employee.uid}/files/${file.uid}`,
          {
            credentials: "include",
            method: "DELETE",
            headers: {
              Accept: "application/json",
            },
          }
        )
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then(() =>
            ElNotification.success({
              title: "O arquivo foi removido com sucesso",
            })
          )
          .catch((e) =>
            ElNotification.error({
              title: "Não foi possível remover o arquivo",
              message: e.message,
            })
          )
          .finally(() => this.fetchFiles());
      }
    },
    downloadFile(attach) {
      if (attach?.file)
        window.open(
          `${this.$store.state.apiUrl}files/${attach.file.uid}`,
          "_blank"
        );
    },
    fetchFiles() {
      if (this.employee) {
        this.isLoading = true;
        fetch(
          `${this.$store.state.apiUrl}employees/${this.employee.uid}/files`,
          {
            credentials: "include",
            headers: {
              Accept: "application/json",
            },
          }
        )
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.files = json))
          .catch(() => (this.hasError = true))
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>