<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>Funcionários cadastrados</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="12" :sm="16">
              <el-row type="flex" justify="end">
                <el-tabs v-model="employeeType">
                  <el-tab-pane label="Efetivos" name="hired"></el-tab-pane>
                  <el-tab-pane label="Experiência" name="trial"></el-tab-pane>
                  <el-tab-pane
                    label="Sem registro"
                    name="unregistered"
                  ></el-tab-pane>
                  <el-tab-pane label="Aviso" name="warning"></el-tab-pane>
                  <el-tab-pane label="Desligados" name="fired"></el-tab-pane>
                </el-tabs>
              </el-row>
            </el-col>

            <el-col :sm="8" :md="6" :lg="4">
              <el-row type="flex" justify="end">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  @click="openEmployeeModal(null)"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="EmployeesByType"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="contact.phone" label="telefone"> </el-table-column>
      <el-table-column
        label="CPF"
        :formatter="(r) => (r?.docs?.cpf ? formatCpf(r?.docs?.cpf) : '-')"
      >
      </el-table-column>
      <el-table-column
        label="admissão"
        :formatter="
          (r) =>
            r?.contract?.start_at
              ? formatDate(new Date(r.contract.start_at))
              : ''
        "
      >
      </el-table-column>
      <el-table-column prop="role.name" label="função"> </el-table-column>
      <el-table-column prop="firm.name" label="empresa"> </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="emp">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openEmployeeFilesModal(emp.row)"
                ><i class="el-icon-paperclip"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openEmployeeModal(emp.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openFireEmployeeModal(emp.row)"
              >
                <i class="el-icon-close"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteEmployee(emp.row)"
                title="
              Remover funcionário?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <employee-modal
      :showModal="showEmployeeModal"
      :employee="employee"
      @close-modal="showEmployeeModal = false"
      @should-update="fetchEmployees"
    ></employee-modal>
    <employee-files-modal
      :showModal="showEmployeeFilesModal"
      :employee="employee"
      @close-modal="showEmployeeFilesModal = false"
    ></employee-files-modal>
    <fire-employee-modal
      :showModal="showFireEmployeeModal"
      :employee="employee"
      @close-modal="showFireEmployeeModal = false"
      @should-update="fetchEmployees"
    ></fire-employee-modal>
  </el-card>
</template>

<script>
import EmployeeModal from "./modals/EmployeeModal.vue";
import { ElNotification } from "element-plus";
import EmployeeFilesModal from "./modals/EmployeeFilesModal.vue";
import FireEmployeeModal from "./modals/FireEmployee.vue";

export default {
  name: "EmployeesPage",
  components: { EmployeeModal, EmployeeFilesModal, FireEmployeeModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    employees: null,
    showEmployeeFilesModal: false,
    showFireEmployeeModal: false,
    employeeType: "hired",
    employee: null,
    showEmployeeModal: false,
  }),
  mounted() {
    this.fetchEmployees();
  },
  computed: {
    EmployeesByType() {
      return (
        this.employees?.filter(
          (e) => e?.contract?.status === this.employeeType
        ) || []
      );
    },
  },
  methods: {
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "desconhecido";
    },
    deleteEmployee(employee) {
      if (employee)
        fetch(`${this.$store.state.apiUrl}employees/${employee.uid}`, {
          credentials: "include",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.text();
          })
          .then(() => {
            this.fetchEmployees();
            this.$notify({
              title: "Funcionário removido com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          })
          .catch((e) => {
            this.$notify({
              title: "Não foi possível remover o funcionário",
              type: "error",
              message: e.message,
              position: "bottom-right",
            });
          });
    },
    openEmployeeModal(e) {
      (this.employee = e), (this.showEmployeeModal = true);
    },
    openEmployeeFilesModal(employee) {
      this.employee = employee;
      this.showEmployeeFilesModal = true;
    },
    openFireEmployeeModal(employee) {
      this.employee = employee;
      this.showFireEmployeeModal = true;
    },
    updateEmployee(employee) {
      if (employee)
        fetch(`${this.$store.state.apiUrl}employees/${employee.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...employee }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.$emit("should-update");
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
              this.$emit("should-update");
              this.$emit("close-modal");
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar a função",
                message: e.message,
                position: "bottom-right",
              });
            }
          )
          .finally(() => {
            this.isLoadingSave = false;
            this.shouldShow = false;
          });
    },
    updateEmployeeStatus(employee, status) {
      employee.is_active = status;
      this.updateEmployee(employee);
    },
    formatCpf(cpf) {
      return cpf
        ? `${("0".repeat(Math.max(11 - cpf.length, 0)) + `${cpf}`).replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
          )}`
        : "";
    },
    fetchEmployees() {
      fetch(`${this.$store.state.apiUrl}employees`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.employees = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>