<template>
  <el-dialog v-model="shouldShow" :title="'Demitir ' + employee?.name">
    <form>
      <base-input
        label="Dias de aviso:"
        mask="#*"
        placeholder="Deixe em branco para demitir imediatamente"
        v-model="days"
      >
      </base-input>
      <el-row type="flex" justify="space-between">
        <h5>Data da demissão:</h5>
        <el-col :md="12"
          ><el-date-picker
            v-model="fired_at"
            type="date"
            disabled
            size="medium"
            format="DD/MM/YYYY"
          >
          </el-date-picker
        ></el-col>
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Demitir</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";

export default {
  props: ["employee", "showModal", "debt"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      employee_: {},
      isLoadingSave: false,
      days: null,
      fired_at: new Date(),
    };
  },
  watch: {
    days(v) {
      this.fired_at = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + Number(v)
      );
    },
  },
  computed: {
    IsAgreement() {
      return this.$route.params.type === "convenios";
    },
    isNew() {
      return !this.debt;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.updateEmployee();
    },
    updateEmployee() {
      if (this.employee)
        fetch(`${this.$store.state.apiUrl}employees/${this.employee.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({
            contract: {
              fired_at: this.fired_at,
              status: this.days ? "warning" : "fired",
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            () => {
              this.$emit("should-update");
              ElNotification.success({
                title: "Funcionário demitido com sucesso",
                position: "bottom-right",
              });
              this.$emit("should-update");
              this.$emit("close-modal");
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível demitir o funcionário",
                message: e.message,
                position: "bottom-right",
              });
            }
          )
          .finally(() => {
            this.isLoadingSave = false;
            this.shouldShow = false;
          });
    },
  },
  name: "EmployeeDebtModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.checkbox-margin-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.text-muted {
  color: grey;
}
.m-0 {
  margin: 0px !important;
}
.mb-1 {
  margin-bottom: 4px;
}
</style>