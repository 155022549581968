<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow" width="70%">
    <form>
      <el-row :gutter="4">
        <el-col :md="5">
          <el-upload
            :action="$store.state.apiUrl + 'files'"
            class="avatar-uploader"
            :auto-upload="true"
            :show-file-list="false"
            :headers="{ 'Access-Control-Allow-Origin': '*' }"
            thumbnail-mode
            :limit="1"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            with-credentials
          >
            <el-image
              :fit="fit"
              v-if="employee_?.photo_url"
              :src="employee_?.photo_url"
              class="avatar"
            />
            <div v-else class="placeholder">
              <i class="el-icon-user" style="font-size: 45px"></i>
              <div class="text-muted">Clique para selecionar</div>
            </div>
          </el-upload>
        </el-col>
        <el-col :md="19">
          <el-col>
            <base-input label="Nome:" v-model="employee_.name"> </base-input>
            <base-input
              label="CPF:"
              v-model="employee_docs.cpf"
              mask="###.###.###-##"
            >
            </base-input>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="3">
        <el-col :md="12">
          <el-select
            remote
            value-key="uid"
            v-model="employee_.marital_status"
            size="medium"
          >
            <template #prefix>Estado Civil:</template>
            <el-option
              v-for="item in MaritalStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="12">
          <el-select
            remote
            value-key="uid"
            v-model="employee_.gender"
            size="medium"
          >
            <template #prefix>Sexo:</template>
            <el-option
              v-for="item in [
                { label: 'Feminino', value: 'female' },
                { label: 'Masculino', value: 'male' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-select
        remote
        value-key="uid"
        v-model="employee_.schooling"
        size="medium"
      >
        <template #prefix>Escolaridade:</template>
        <el-option
          v-for="item in Schooling"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <el-collapse>
        <el-collapse-item title="Dados Pessoais">
          <el-row :gutter="3">
            <el-col :md="7">
              <base-input label="RG:" v-model="employee_docs.rg" mask="#*">
              </base-input>
            </el-col>

            <el-col :md="9">
              <base-input
                label="Emissor:"
                hint="Órgão/Estado"
                :modelValue="employee_docs.rg_emitter"
                @update:modelValue="
                  employee_docs.rg_emitter = ($event || '').toUpperCase()
                "
              >
              </base-input>
            </el-col>
            <el-col :md="8">
              <el-date-picker
                v-model="employee_docs.rg_emission"
                type="date"
                size="medium"
                placeholder="Data emissão:"
                :shortcuts="shortcuts"
                format="DD/MM/YYYY"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="3">
            <el-col :md="7">
              <base-input
                label="Título:"
                v-model="employee_docs.voting_id"
                mask="#*"
              >
              </base-input>
            </el-col>

            <el-col :md="9">
              <base-input
                label="Zona:"
                hint="Zona/Seção"
                mask="###!/###"
                v-model="employee_docs.voting_zone"
              >
              </base-input>
            </el-col>
            <el-col :md="8">
              <el-date-picker
                v-model="employee_docs.voting_emission"
                type="date"
                size="medium"
                placeholder="Data emissão:"
                :shortcuts="shortcuts"
                format="DD/MM/YYYY"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="3">
            <el-col :md="7">
              <base-input
                label="Nacionalidade:"
                v-model="employee_.nationality"
              >
              </base-input>
            </el-col>

            <el-col :md="9">
              <base-input
                label="Naturalidade:"
                v-model="employee_.place_of_birth"
              >
              </base-input>
            </el-col>
            <el-col :md="8">
              <el-date-picker
                v-model="employee_.birthday"
                type="date"
                size="medium"
                placeholder="Data de nasc.:"
                :shortcuts="shortcuts"
                format="DD/MM/YYYY"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item title="Filiação">
          <el-row :gutter="3">
            <el-col :md="14">
              <base-input label="Nome do Pai:" v-model="employee_.fathers_name">
              </base-input>
            </el-col>
            <el-col :md="10">
              <base-input
                label="Nacionalidade:"
                v-model="employee_.fathers_nationality"
              >
              </base-input>
            </el-col>
          </el-row>
          <el-row :gutter="3">
            <el-col :md="14">
              <base-input label="Nome da Mãe:" v-model="employee_.mothers_name">
              </base-input>
            </el-col>
            <el-col :md="10">
              <base-input
                label="Nacionalidade:"
                v-model="employee_.mothers_nationality"
              >
              </base-input>
            </el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="Contato">
          <el-row :gutter="4">
            <el-col :md="12" :xs="24">
              <base-input
                label="Telefone:"
                hint="Telefone com DDD"
                mask="!(##!)#! ####!-####"
                v-model="employee_contact.phone"
              >
              </base-input
            ></el-col>
            <el-col :md="12" :xs="24"
              ><base-input
                label="Email:"
                hint="Informe o email completo"
                v-model="employee_contact.email"
              >
              </base-input
            ></el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="Endereço">
          <base-input
            label="CEP:"
            hint="00.0000-00"
            mask="##!.###!-###"
            v-on:blur="getAddressFromCEP"
            v-model="employee_addr.postal_code"
          >
          </base-input>
          <el-row :gutter="4">
            <el-col :md="16" :xs="24">
              <base-input label="Rua:" v-model="employee_addr.street">
              </base-input>
            </el-col>
            <el-col :md="8" :xs="24"
              ><base-input
                mask="#*"
                label="Número:"
                v-model="employee_addr.number"
              >
              </base-input
            ></el-col>
          </el-row>

          <el-row :gutter="4">
            <el-col :md="8" :xs="24">
              <el-select v-model="employee_addr.state" size="medium">
                <!--<template #prefix>Estado:</template>-->
                <el-option
                  v-for="item in States"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option> </el-select
            ></el-col>
            <el-col :md="16" :xs="24"
              ><base-input label="Cidade:" v-model="employee_addr.city">
              </base-input
            ></el-col>
          </el-row>

          <el-row :gutter="4">
            <el-col :md="12" :xs="24">
              <base-input label="Bairro:" v-model="employee_addr.place">
              </base-input>
            </el-col>
            <el-col :md="12" :xs="24"
              ><base-input
                label="Complemento:"
                v-model="employee_addr.complement"
              >
              </base-input
            ></el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="Dados Trabalhistas">
          <base-input
            label="PIS/PASEP:"
            mask="#*"
            v-model="employee_registration.pis"
          >
          </base-input>
          <el-row :gutter="4">
            <el-col :md="8" :xs="24">
              <base-input
                label="CTPS:"
                v-model="employee_registration.ctps"
                mask="#*"
              >
              </base-input>
            </el-col>
            <el-col :md="8" :xs="24"
              ><base-input
                mask="#*"
                label="Série:"
                v-model="employee_registration.serial"
              >
              </base-input
            ></el-col>
            <el-col :md="8">
              <el-select v-model="employee_registration.state" size="medium">
                <!--<template #prefix>Estado:</template>-->
                <el-option
                  v-for="item in States"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-row :gutter="4">
            <el-col :md="8" :xs="24"
              ><base-input label="CNH:" v-model="employee_docs.cnh">
              </base-input
            ></el-col>
            <el-col :md="8" :xs="24"
              ><el-select v-model="employee_docs.cnh_cat" size="medium">
                <!--<template #prefix>Estado:</template>-->
                <el-option
                  v-for="item in ['A', 'B', 'C', 'D', 'E']"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option> </el-select
            ></el-col>
            <el-col :md="8" :xs="24">
              <el-select v-model="employee_docs.cnh_state" size="medium">
                <!--<template #prefix>Estado:</template>-->
                <el-option
                  v-for="item in States"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option> </el-select
            ></el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="Dados Bancários">
          <el-select
            v-model="employee_bank.bank_id"
            size="medium"
            value-key="code"
            placeholder="Banco:"
            filterable
          >
            <!--<template #prefix>Bancos:</template>-->
            <el-option
              v-for="item in Banks"
              :key="item.code"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-row :gutter="4">
            <el-col :md="12" :xs="24">
              <base-input
                label="Agência:"
                v-model="employee_bank.agency"
                mask="#*"
              >
              </base-input>
            </el-col>
            <el-col :md="12" :xs="24"
              ><base-input
                mask="#*"
                label="C/C:"
                v-model="employee_bank.account"
              >
              </base-input
            ></el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="Dados de Contrato">
          <el-select
            v-model="employee_.firm_id"
            size="medium"
            placeholder="Empresa:"
          >
            <!--<template #prefix>Empresa:</template>-->
            <el-option
              v-for="item in Firms"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="employee_contract.status"
            size="medium"
            placeholder="Estado:"
          >
            <!--<template #prefix>Bancos:</template>-->
            <el-option
              v-for="item in Status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            remote
            value-key="uid"
            v-model="employee_.role_id"
            placeholder="Cargo:"
            size="medium"
          >
            <!--<template #prefix>Cargo:</template>-->
            <el-option
              v-for="item in Roles"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
          <el-row :gutter="4">
            <el-col :md="8" :xs="24">
              <base-input
                label="Salário Fixo:"
                v-model="employee_contract.amount_fixed"
                type="money"
              >
              </base-input>
            </el-col>
            <el-col :md="8" :xs="24"
              ><base-input
                type="money"
                label="Comissão:"
                v-model="employee_contract.comission"
              >
              </base-input
            ></el-col>
            <el-col :md="8" :xs="24"
              ><base-input
                type="money"
                label="Salário Real:"
                v-model="employee_contract.amount_real"
              >
              </base-input
            ></el-col>
          </el-row>
          <el-row :gutter="4">
            <el-col :md="8" :xs="24">
              <el-date-picker
                v-model="employee_contract.start_at"
                type="date"
                size="medium"
                placeholder="Data admissão:"
                :shortcuts="shortcuts"
                format="DD/MM/YYYY"
              >
              </el-date-picker>
            </el-col>
            <el-col :md="8" :xs="24"
              ><el-date-picker
                v-model="employee_contract.register_at"
                type="date"
                size="medium"
                placeholder="Data efetivação:"
                :shortcuts="shortcuts"
                format="DD/MM/YYYY"
              >
              </el-date-picker
            ></el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
      <el-row>
        <h5>Observações:</h5>
      </el-row>
      <base-input
        type="textarea"
        label="Observações:"
        v-model="employee_.obs"
      ></base-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";

export default {
  props: ["employee", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      employee_: {},
      imageUrl: null,
      roles: null,
      employee_contact: {},
      employee_docs: {},
      employee_bank: {},
      employee_registration: {},
      employee_contract: {},
      banks: null,
      firms: null,
      employee_addr: {},
      isLoadingSave: false,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  watch: {
    employee(v) {
      this.employee_ = { ...(v || {}) };
      this.employee_.role_id = v?.role?.uid;
      this.employee_.firm_id = v?.firm?.uid;
      this.employee_docs = { ...(v?.docs || {}) };
      this.employee_bank = { ...(v?.bank || {}) };
      this.employee_registration = { ...(v?.registration || {}) };
      this.employee_contact = { ...(v?.contact || {}) };
      this.employee_addr = { ...(v?.addr || {}) };
      this.employee_contract = { ...(v?.contract || {}) };
    },
  },
  mounted() {
    this.fetchRoles();
    this.fetchBanks();
    this.fetchFirms();
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar funcionário" : "Cadastrar funcionário";
    },
    isNew() {
      return !this.employee;
    },
    Roles() {
      return this.roles || [];
    },
    Banks() {
      return (
        this.banks /*?.map((b) => ({
          value: b.id,
          label: b.name,
          key: b.code,
        }))*/ || []
      );
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    Schooling() {
      return [
        "Superior Completo",
        "Superior Cursando",
        "Ensino Técnico",
        "Ensino Médio Completo",
        "Ensino Médio Incompleto",
        "Fundamental Completo",
        "Fundamental Incompleto",
      ];
    },
    Status() {
      return [
        {
          value: "unregistered",
          label: "Sem registro",
        },
        {
          value: "trial",
          label: "Experiência",
        },
        {
          value: "hired",
          label: "Efetivo",
        },
        {
          value: "warning",
          label: "Aviso prévio",
        },
        {
          value: "fired",
          label: "Desligado",
        },
      ];
    },
    States() {
      return this.$store.state.constants.states;
    },
    Firms() {
      return this.firms || [];
    },
    MaritalStatus() {
      return [
        {
          label: "Solteiro",
          value: "single",
        },
        {
          label: "Casado",
          value: "married",
        },
        {
          label: "Divorciado",
          value: "divorced",
        },
        {
          label: "Outros",
          value: "others",
        },
      ];
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateEmployee() : this.createEmployee();
    },
    createEmployee() {
      fetch(`${this.$store.state.apiUrl}employees`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          ...this.employee_,
          contract: this.employee_contract,
          contact: this.employee_contact,
          bank: this.employee_bank,
          addr: this.employee_addr,
          docs: this.employee_docs,
          registration: this.employee_registration,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.employee_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar o funcionário",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateEmployee() {
      fetch(`${this.$store.state.apiUrl}employees/${this.employee_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({
          ...this.employee_,
          contract: this.employee_contract,
          contact: this.employee_contact,
          bank: this.employee_bank,
          addr: this.employee_addr,
          docs: this.employee_docs,
          registration: this.employee_registration,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("should-update");
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar o funcionário",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
    handleAvatarSuccess(res) {
      this.employee_.photo_url = res.url; //URL.createObjectURL(file.raw);
    },
    getPaddingLeftForSelectPrefix(prefix) {
      return `padding-left: ${(prefix?.length || 0) * 7.5}px;`;
    },
    getAddressFromCEP() {
      if (this.employee_addr.postal_code) {
        fetch(`https://viacep.com.br/ws/${this.employee_addr.postal_code}/json`)
          .then((r) => {
            if (r.status === 200) return r.json();
            else throw r.json();
          })
          .then((json) => {
            this.employee_addr.street = json["logradouro"];
            this.employee_addr.place = json["bairro"];
            this.employee_addr.state = json["uf"];
            this.employee_addr.city = json["localidade"];
            this.employee_addr.complement = json["complemento"];
          })
          .catch(() =>
            ElNotification.error({
              title: "Não foi possível obter o endereço a partir do CEP",
              message: "Por favor, preencha o endereço manualmente",
            })
          );
      }
    },
    fetchRoles() {
      fetch(`${this.$store.state.apiUrl}roles`, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.roles = e;
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao buscar a lista de cargos",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    fetchFirms() {
      fetch(`${this.$store.state.apiUrl}firms`, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.firms = e;
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao buscar a lista de empresas",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    fetchBanks() {
      fetch(`${this.$store.state.apiUrl}banks`, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.banks = e;
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao buscar a lista de bancos",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.includes("image");
      const isLessThan5Mb = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message.error("Você deve selecionar uma imagem");
      }
      if (!isLessThan5Mb) {
        this.$message.error("O arquivo deve ter menos de 5 Mb.");
      }
      return isImage && isLessThan5Mb;
    },
  },
  name: "DriverModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.checkbox-margin-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.avatar-uploader {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;

  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.placeholder {
  margin: 40px 0 16px;
  line-height: 35px;
}
.input-prefix {
  margin-left: 10px !important;
}
</style>
