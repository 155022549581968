<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow" append-to-body>
    <form>
      <el-row type="flex" justify="space-between">
        <el-col :md="24">
          <el-upload
            :action="API_URL"
            ref="fileUpload"
            with-credentials
            :thumbnail-mode="false"
            :data="employee_file"
            list-type="picture"
            :auto-upload="false"
            :on-success="onSuccess"
            :on-error="onError"
            accept=".pdf,.txt,.xls,.docx,.doc,.xlsx"
          >
            <template #default>
              <el-button type="text">Selecionar Arquivo</el-button>
            </template>
          </el-upload>
        </el-col>
      </el-row>
      <base-input label="Descrição" v-model="employee_file.obs"></base-input>
      <el-date-picker
        v-model="employee_file.expires_at"
        type="date"
        size="medium"
        placeholder="Vencimento:"
        :shortcuts="shortcuts"
        format="DD/MM/YYYY"
      >
      </el-date-picker>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";

export default {
  props: ["file", "showModal", "employee"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      isLoadingSave: false,
      employee_file: {},
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },

  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar arquivo" : "Cadastrar arquivo";
    },
    isNew() {
      return !this.file;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    API_URL() {
      return `${this.$store.state.apiUrl}employees/${this?.employee?.uid}/files`;
    },
    API_HEADERS() {
      return {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      };
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateFile() : this.createFile();
    },
    onSuccess() {
      ElNotification.success({ title: "O arquivo foi salvo" });
      this.$emit("should-update");
      this.$emit("close-modal");
    },
    onError(e) {
      ElNotification.error({
        title: "Ocorreu um erro",
        message: e.response.message,
      });
      this.$emit("close-modal");
    },
    createFile() {
      this.$refs.fileUpload.submit();
      /*if (this.employee)
        fetch(`${this.$store.state.apiUrl}employees/${this?.employee?.uid}`, {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(this.employee_),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.$emit("should-update");
              this.shouldShow = false;
              this.employee_ = {};
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
              this.$emit("close-modal");
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível cadastrar o produto",
                message: e[0].message,
                position: "bottom-right",
              });
            }
          )
          .finally(() => {
            this.isLoadingSave = false;
          });*/
    },
    updateFile() {
      fetch(`${this.$store.state.apiUrl}products/${this.employee_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.employee_, price: null }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar o produto",
              message: e[0].message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "DriverModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>